import WorkIm from "../assets/projects/workImg.jpeg";
import RealEst from "../assets/projects/realestate.jpg";

export const data = [
  {
    id: 1,
    name: "Wordle",
    image: WorkIm,
    github: "",
    live: "/wordle",
  },
  {
    id: 2,
    name: "React JS Application",
    image: RealEst,
    github: "",
    live: "",
  },
  {
    id: 3,
    name: "React JS Application",
    image: WorkIm,
    github: "",
    live: "",
  },
  {
    id: 4,
    name: "React JS Application",
    image: RealEst,
    github: "",
    live: "",
  },
];
