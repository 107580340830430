import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Wordle from "./components/Wordle";
import { HomePage } from "./HomePage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  { path: "/wordle", element: <Wordle /> },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
