import React, { useRef, useState } from "react";
import { useEffect } from "react";
import {
  Autocomplete,
  Button,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";

const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");

const MySelect = ({ value, onChange, id, label }) => {
  return (
    <Autocomplete
      multiple
      id="tags-standard"
      options={alphabet}
      getOptionLabel={(letter) => letter}
      onChange={onChange}
      // defaultValue={[top100Films[13]]}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label={label} />
      )}
    />
    // <Select value={value} label={label} onChange={onChange}>
    //   {alphabet.map((l) => (
    //     <MenuItem value={l}>{l}</MenuItem>
    //   ))}
    // </Select>
  );
};

const Wordle = () => {
  const inputRef = useRef(null);
  const [word, setWord] = useState([]);
  console.log("🚀 ~ file: Wordle.jsx:38 ~ Wordle ~ word", word);
  const [good, setGood] = useState([]);

  const [bad, setBad] = useState([]);

  const handleSetWord = (e) => {
    try {
      setWord((word) => {
        const newWord = [...word];
        newWord[e.target.name] = e.target.value.slice(-1);
        return newWord;
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleSetGood = (e, values) => setGood(values);
  const handleSetBad = (e, values) => setBad(values);

  const [list, setList] = useState([]);
  //   const [res, setRes] = useState("");

  const findWord = async (e) => {
    e.preventDefault();
    let url = "/api/wordle";
    let params = new URLSearchParams({ word: word.join(""), good, bad });

    const response = await fetch(`${url}?${params}`);
    const ans = await response.json();
    setList(ans);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div
      style={{
        margin: "auto",
        // width: "50%",
        display: "grid",
        padding: "16px",
        gap: "16px",
        gridTemplateAreas: `
                            'form empty result'
                            'form empty result' 
                            'other empty result' 
                           `,
      }}
    >
      <div
        style={{
          display: "grid",
          gridArea: "form",
          gap: "16px",
        }}
      >
        <Typography variant="h6">Wordle Helper</Typography>
        <form onSubmit={findWord}>
          <div style={{ display: "grid", gap: "16px" }}>
            <div>
              {[0, 1, 2, 3, 4].map((n) => (
                <TextField
                  inputProps={{ min: 0, style: { textAlign: "center" } }}
                  style={{ width: "54px", textAlign: "center" }}
                  value={word[n]}
                  name={n}
                  onChange={handleSetWord}
                />
              ))}
            </div>

            {/* <TextField
              label="Word"
              ref={inputRef}
              id="word"
              name="word"
              type="text"
              value={word}
              onChange={handleSetWord}
            /> */}

            <MySelect
              label="Good"
              id="good"
              value={good}
              onChange={handleSetGood}
            />
            <MySelect
              label="Bad"
              id="bad"
              value={bad}
              onChange={handleSetBad}
            />
            <Button variant="outlined" type="submit" fullWidth>
              Search
            </Button>
          </div>
        </form>
      </div>

      <div
        style={{
          display: "grid",
          gridArea: "result",
        }}
      >
        <List>
          {list.map((item) => (
            <ListItem>{item.word}</ListItem>
          ))}
        </List>
      </div>

      {/* {res.toString()} */}
    </div>
  );
};

export default Wordle;
