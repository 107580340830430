import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as sw from "./swRegistration";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

sw.register();
